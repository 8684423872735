import * as React from "react"
import { Link, graphql } from 'gatsby'

import Seo from "../components/seo"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"


const ttt = "TITLE"
const ddd = "DESCRIPT"


// markup
const NewsDetailPage = ({data}) => {
  return (
    <Layout isHomePage={true} headerClassName="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={ttt} description={ddd} />
      <main id="news-detail">
    <h2><StaticImage src="../images/ttl-news@2x.png" alt="ニュース" /></h2>
    <article className="inner">
      <section>
        <h1>グルメスポット記事タイトル</h1>
        <h2>サブタイトル</h2>
        <StaticImage src="../images/sightseening-1.png" alt="" />
        <p>
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。
        </p>
        <p>
          <Link to="https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16">
            https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16
          </Link>
        </p>
        <hr />
        <StaticImage src="../images/sightseening-1.png" alt="" />
        <p>
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。
        </p>
        <p>
          <Link to="https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16">
            https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16
          </Link>
        </p>
        <hr />
        <StaticImage src="../images/sightseening-1.png" alt="" />
        <p>
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。
        </p>
        <p>
          <Link to="https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16">
            https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16
          </Link>
        </p>
        <hr />
        <StaticImage src="../images/sightseening-1.png" alt="" />
        <p>
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
          様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。
        </p>
        <p>
          <Link to="https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16">
            https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16
          </Link>
        </p>
        <hr />

        <StaticImage src="../images/sightseening-1.png" alt="" />
        <p>
                  様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
                  様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。<br />
                  様々な情報・サービス・趣味嗜好が溢れ過剰に供給されることで、人それぞれの価値観や生き方は移ろいゆく。そんな世の中でアウトドアを趣味として楽しむことは、一つの生き方たスタイルだととらえ、自由なスタイルを楽しめるプロダクトを生み出したいと考えています。
                </p>
        <p>
                  <Link to="https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16">
                    https://www.youtube.com/watch?v=2pZkj8nsHlI&list=PLwr0SJS7cwW0M4t5wjs_pZRYldHU6BZtp&index=16
                  </Link>
                </p>
        <hr />


      </section>
    </article>
    <Link className="link-btn" to="#"><span>ニュース一覧へ</span></Link>
  </main>

    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allWpNews(sort: { fields: [date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          uri
          title
          slug
          date(formatString: "Do MMMM")
        }
      }
    }
  }
`

export default NewsDetailPage